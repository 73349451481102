@use '../abstracts/colors/semantic/border' as borderColor;
@use '../base/typography';

@mixin table-wrapper() {
  max-width: 100%;
  overflow-y: auto;
}

@mixin private-table-wrapper-styles() {
  .ff-table-wrapper {
    @include table-wrapper;
  }
}

@mixin table() {
  @include typography.size-19;
  width: 100%;

  tr {
    th {
      font-weight: 600;
    }

    th, td {
      border-bottom: 1px solid borderColor.$ff-border-clr-100;
      padding: 8px 0;
    }
  }
}

@mixin private-table-styles() {
  .ff-table {
    @include table;
  }
}

