@use 'sass:map';
@use '../abstracts/breakpoints';

@mixin container-query($br) {
  $size: map.get(breakpoints.$ff-content-widths, $br);
  @container (min-width: #{$size}) {
    @content;
  }
}

@mixin mq($br) {
  $size: map.get(breakpoints.$ff-content-widths, $br);
  @media only screen and (min-width: $size) {
    @content;
  }
}
