@use '../../abstracts/colors/palette';
@use '../../abstracts/colors/semantic/border';
@use '../../abstracts/colors/semantic/text';
@use '../../base/typography';
@use '../../utilities/outline';

@mixin radiobutton() {
  appearance: none;
  background-color: palette.$ff-white-100;
  border: 1px solid border.$ff-border-clr-150;
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  flex-shrink: 0;
  height: 24px;
  margin: 0;
  place-content: center;
  width: 24px;

  &:focus {
    @include outline.focused-red();
  }

  &::before {
    content: '';
    background-color: text.$ff-text-clr-100;
    border: 0.5px solid palette.$ff-white-100;
    border-radius: 50%;
    height: 12px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    width: 12px;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:disabled {
    border-color: border.$ff-border-clr-70;
    cursor: not-allowed;

    label:has(&) {
      color: text.$ff-text-clr-30;
      cursor: default;
    }

    &:checked::before {
      background-color: text.$ff-text-clr-30;
    }
  }
}

@mixin radiobutton-label() {
  @include typography.normal;
  display: flex;
  flex-direction: row;
  gap: 8px;
  place-items: flex-start;
}

@mixin private-radiobutton-styles() {
  .ff-radiobutton {
    @include radiobutton;
  }
}

@mixin private-radiobutton-label-styles() {
  label:has(.ff-radiobutton) {
    @include radiobutton-label;
  }
}
