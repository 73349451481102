@use '../abstracts/colors/palette';
@use '../abstracts/colors/semantic/text';

@mixin focused() {
  outline: text.$ff-text-clr-70 auto 1px;
  outline-offset: 2px;
}

@mixin focused-red() {
  outline: solid 3px palette.$ff-red-20;
  outline-offset: 0;
}

@mixin error() {
  outline: 1px solid palette.$ff-red-100;
}

@mixin focused-box-shadow() {
  box-shadow: 0 0 0 3px rgba(151, 154, 154, 0.18);
}

@mixin clip-top() {
  clip-path: polygon(
    -3px 1px,
    -3px calc(100% + 3px),
    calc(100% + 3px) calc(100% + 3px),
    calc(100% + 3px) 1px
  );
}

@mixin clip-bottom() {
  clip-path: polygon(
    -3px -3px,
    -3px 100%,
    calc(100% + 3px) 100%,
    calc(100% + 3px) -3px
  );
}
