@use '../abstracts/breakpoints';
@use '../base/typography';

@mixin h1() {
  @include typography.bold;
  font-size: 1.875rem;
  line-height: 2.375rem;

  @media (min-width: breakpoints.$ff-br-content-small) {
    font-size: 3.5rem;
    line-height: 4.375rem;
  }
}

@mixin h2() {
  @include typography.bold;
  font-size: 1.875rem;
  line-height: 2.375rem;

  @media (min-width: breakpoints.$ff-br-content-small) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@mixin h3() {
  @include typography.bold;
  font-size: 1.375rem;
  line-height: 1.75rem;

  @media (min-width: breakpoints.$ff-br-content-small) {
    font-size: 1.6875rem;
    line-height: 2.125rem;
  }
}

@mixin private-h1-styles() {
  .ff-h1 {
    @include h1;
  }
}

@mixin private-h2-styles() {
  .ff-h2 {
    @include h2;
  }
}

@mixin private-h3-styles() {
  .ff-h3 {
    @include h3;
  }
}
