@use '../../abstracts/colors/palette';
@use '../../abstracts/colors/semantic/border';
@use '../../abstracts/colors/semantic/text';
@use '../../base/typography';
@use '../../utilities/functions';
@use '../../utilities/outline';
@use '../../utilities/visually-hidden';

// SVG requires that we escape the # in the hex color
$clr-checkmark: functions.encode-color(text.$ff-text-clr-100);
$clr-checkmark-disabled: functions.encode-color(text.$ff-text-clr-30);

@mixin checkbox() {
  input {
    @include visually-hidden.visually-hidden;
  }

  & {
    @include typography.normal;

    display: grid;
    gap: 6px;
    grid-template-columns: auto 1fr;
    padding-block: 6px;

    // checkbox (unchecked)
    &::before {
      background-color: palette.$ff-white-100;
      border: 1px solid border.$ff-border-clr-150;
      border-radius: 3.5px;
      content: '';
      height: 24px;
      width: 24px;
    }

    &.processing {
      &::before {
        display: none;
      }
    }

    &:focus-within {
      &::before {
        @include outline.focused-red;
      }
    }
  }

  // checked checkbox
  &:has(input:checked) {
    &::before {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cg transform='matrix(1.02147,0,0,1.02147,-3.31722,-3.32377)'%3E%3Cg id='_.checkbox-icon-master' serif:id='.checkbox icon master'%3E%3Cpath id='foreground--Stroke-' serif:id='foreground (Stroke)' d='M21.397,11.09L13.048,20.247L8.15,15.637L9.35,14.363L12.952,17.754L20.104,9.911L21.397,11.09Z' style='fill:#{$clr-checkmark};'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  // disabled checkbox
  &:has(input:disabled) {
    color: text.$ff-text-clr-30;
    cursor: default;

    &::before {
      border-color: border.$ff-border-clr-70;
      cursor: not-allowed;
    }
  }

  &:has(input:checked:disabled) {
    &::before {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cg transform='matrix(1.02147,0,0,1.02147,-3.31722,-3.32377)'%3E%3Cg id='_.checkbox-icon-master' serif:id='.checkbox icon master'%3E%3Cpath id='foreground--Stroke-' serif:id='foreground (Stroke)' d='M21.397,11.09L13.048,20.247L8.15,15.637L9.35,14.363L12.952,17.754L20.104,9.911L21.397,11.09Z' style='fill:#{$clr-checkmark-disabled};'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

@mixin private-checkbox-styles() {
  .ff-checkbox {
    @include checkbox;
  }
}
