@use '../abstracts/borders';
@use '../abstracts/breakpoints';
@use "../abstracts/colors/palette";

/**
 * Defaults for most modals
 */
@mixin modal-defaults {
  display: block;
  max-width: 555px;

  .modal__body {
    font-size: 20px;
    padding: 0;
  }

  .modal__footer {
    align-items: center;
    border: none;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: 1em 0 0;
    min-width: 50px;

    .buttons__button {
      border-radius: borders.$ff-border-radius-default;
      overflow: hidden;
    }

    @media (min-width: breakpoints.$ff-br-content-medium) {
      align-items: stretch;
      flex-flow: row wrap;
      justify-content: space-evenly;

      .buttons__button {
        flex: 1 0 50px;
        margin: 0 3px;
        max-width: 40%;
        min-height: 42px;
      }
    }
  }

}

@mixin private-modal-styles {
  .ff-modal {
    @include modal-defaults;
  }
}
