@use "variables" as var;

$clr-placeholder: var.$clr-tide;
$clr-label: var.$clr-text;
$font-size-input: 19px;
$font-size-label: 19px;

@mixin focused_input() {
  outline:        var.$clr-text-70 auto 1px;
  outline-offset: 2px;
}

.form {
  align-items: flex-start;
  display:     flex;
  flex-flow:   row wrap;
  gap:         .5em;

  .form-group,
  .form-array {
    align-items: flex-start;
    display:     flex;
    flex:        1 1 100%;
    flex-flow:   row wrap;
    gap: .5em;

    &--dual {
      @media(min-width: var.$br-content-wide) {
        flex: 1 1 40%;
      }
    }

    .form-control {
      flex:   1 1 100%;
      transition-property: box-shadow, border-color;
      transition-duration: 0.15s;
      transition-timing-function: ease-in-out;

      &--dual {
        flex: 1 1 100%;

        @media(min-width: var.$br-content-wide) {
          flex: 1 1 40%;
          gap: .75em;
        }
      }

      .input-group {
        &__item:not(:first-child) {
          margin-top: calc(#{var.$vertical-gutter} / 2);
        }
      }

      .radio-group {
        label {
          font-weight: normal;
          cursor: pointer;
        }
      }

      .radio-group-inline {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        gap: 0 1rem;
      }

      .helper-text {
        font-size: 90%;
        margin: 0 0 .3em;
      }

      .validation {
        color: var.$clr-red;
        font-size: 80%;
        margin-top: 0.25rem;
        padding: 0 0 0 .5rem;
        width: 100%;
      }
    }

    + .form-group:not(.form-group--dual) {
      .form-control {
        margin: calc(#{var.$vertical-gutter} / 1.5) 0 0;
      }
    }
  }

  /* No spacing on top */
  form > .form-group:first-of-type > .form-control {
    margin-top: 0;
  }

  label {
    color:          $clr-label;
    cursor:         pointer;
    display:        inline-block;
    font-size:      $font-size-label;
    font-weight:    600;
    letter-spacing: 0.1px;
    margin:         0 0 .3em;
  }

  textarea {
    resize: none;
    min-height: 100px;
  }

  app-dropdown,
  app-multiselect {
    display: block;
    width: 100%;
  }

  input:not([type="checkbox"]):not([type="radio"]):not([ffngxinput]),
  .input,
  select,
  textarea {
    background-color: var.$clr-white;
    border-width:  1px;
    border-color:  var.$clr-border;
    border-radius: var.$border-radius-default;
    border-style:  solid;
    box-sizing:    border-box;
    color:         var.$clr-text;
    display:       block;
    font-size:     $font-size-input;
    font-family:   'Source Sans Pro', sans-serif;
    padding:       15px 18px;
    width:         100%;

    &.secondary {
      background-color: transparent;
      cursor:           pointer;
    }

    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      opacity: 1;
    }

    &::placeholder { /* Most modern browsers support this now. */
      color: $clr-placeholder;
    }
  }

  input,
  select,
  textarea {
    &:focus-visible {
      @include focused_input;
    }
  }

  input:not([ffngxinput]),
  select,
  textarea {
    &:disabled,
    &[disabled] {
      background: var.$clr-gainsboro !important;
      cursor:     not-allowed !important;
    }
  }

  input:not([ffngxinput]),
  textarea {
    &:read-only,
    &[readonly] {
      background: var.$clr-sand-125 !important;
      cursor:     pointer !important;
    }
  }

  /* Separate rule for select, some browsers deem selects to always be :read-only */
  select[readonly],
  select.readonly {
    background: var.$clr-sand-125 !important;
    cursor:     pointer !important;
  }

  input[type="checkbox"],
  input[type="radio"]{
    &:hover {
      cursor: pointer;
    }
  }

  &.submitted {
    input:not([type="checkbox"]):not([type="radio"]),
    select,
    textarea {
      &.ng-invalid {
        border-color: var.$clr-red;
      }
    }

    input[type="radio"].ng-invalid:after {
      border:        1px solid var.$clr-red;
      border-radius: 4px;
      content:       '';
      display:       block;
      height:        inherit;
      margin:        -3px 0 0 -3px;
      padding:       2px;
      width:         inherit;
    }
  }

  .buttons {
    width: 100%;
  }
}

.custom {
  // https://designtheway.com/custom-radio-button-and-checkbox/
  // https://codepen.io/anandaprojapati/pen/bWwRLO
  input[type="checkbox"],
  input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
