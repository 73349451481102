/**
 * Any content hidden from sight but available for accessibility tools such as screen readers
 */

@mixin visually-hidden() {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin private-visually-hidden-styles() {
  .ff-visually-hidden {
    @include visually-hidden;
  }
}
