@use '../abstracts/colors/semantic/link';
@use '../utilities/outline';

@mixin link() {
  color: link.$ff-link-clr;
  cursor: pointer;
  text-decoration: underline;

  &:visited {
    color: link.$ff-link-clr-hover;
  }

  &:hover {
    color: link.$ff-link-clr-hover;
    text-decoration: none;
  }

  &:focus,
  &:focus-visible {
    @include outline.focused;
  }

  @media (hover: none) and (pointer: coarse) {
    // Increase trigger size for touch
    display: inline-block;
    padding: 0.5rem;
  }

}

@mixin private-link-styles() {
  .ff-link {
    @include link;
  }
}
