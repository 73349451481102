@use '../abstracts/colors/palette';
@use '../abstracts/colors/semantic/text';
@use '../base/typography' as typ;

@mixin reset() {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    background-color: palette.$ff-sand-50;
    font-family: 'Source Sans Pro', sans-serif;
    color: text.$ff-text-clr-100;
    @include typ.size-19;
    @include typ.normal;
    min-height: 100vh;
    text-align: left;
  }

  body {
    margin: 0;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
}
