@use '../abstracts/colors/palette';

@mixin code() {
  background-color: palette.$ff-sand-60;
  border: 1px solid palette.$ff-gray-120;
  border-radius: 5px;
  color: palette.$ff-logo-red-100;
  padding: 0 5px;
}

@mixin private-code-styles() {
  .ff-code {
    @include code;
  }
}
