@use "variables" as var;

/**
 * Font styles commonly used in Figma:
 */
@mixin font-9() {
  font-size:   9px;
  line-height: 11px;
}

@mixin font-12() {
  font-size:   12px;
  line-height: 15px;
}

@mixin font-14() {
  font-size:   14px;
  line-height: 18px;
}

@mixin font-16() {
  font-size:   16px;
  line-height: 20px;
}

@mixin font-17() {
  font-size:   17px;
  line-height: 22px;
}

@mixin font-18() {
  font-size:   18px;
  line-height: 23px;
}

@mixin font-19() {
  font-size:   19px;
  line-height: 24px;
}

@mixin font-22() {
  font-size:   22px;
  line-height: 28px;
}

@mixin font-24() {
  font-size:   24px;
  line-height: 30px;
}

@mixin font-27() {
  font-size:   27px;
  line-height: 34px;
}

@mixin font-normal() {
  font-weight:    400;
  letter-spacing: 0;
}

@mixin font-semibold() {
  font-weight:    600;
  letter-spacing: 0;
}

@mixin font-bold() {
  font-weight:    700;
  letter-spacing: 0;
}


/**
 * Links
 */
a {
  color: var.$clr-red-dark;
  cursor: pointer;
  text-decoration: underline;

  &:visited {
    color: darken(var.$clr-red-dark, 10%);
  }

  &:hover {
    color: var.$clr-red-dark;
    text-decoration: none;
  }

  @media (hover: none) and (pointer: coarse) {
    // Increase trigger size for touch
    display: inline-block;
    padding: .5rem;
  }
}

/**
 * Headings
 */
h1, h2, h3, h4 {
  hyphens:    auto;
  margin:     .75rem 0;
  word-break: break-word;
}

h1:has(+.subtitle),
h2:has(+.subtitle),
h3:has(+.subtitle),
h4:has(+.subtitle) {
  margin-bottom: 0;
}

h1 + .subtitle,
h1 > .subtitle,
h2 + .subtitle,
h2 > .subtitle,
h3 + .subtitle,
h3 > .subtitle {
  @include font-22;
  @include font-normal;
  display:     block;
  line-height: 1.2em;
  margin:      .1rem 0 2rem;

  @media(min-width: var.$br-content-medium) {
    margin-top: .2rem;
  }
  @media(min-width: var.$br-content-wide) {
    margin-top: .3rem;
  }
}

h1 {
  @include font-bold;
  font-size:      2.86rem;
  font-style:     normal;
  line-height:    52px;
  text-align:     left;

  &.login {
    font-size:   32px;
    line-height: 40px;
  }
}

h2 {
  @include font-bold;
  font-size: 2.37rem;
}

h3 {
  font-size: 1.78rem;
}

h4 {
  font-size: 1.33rem;
}

main h1 + .intro {
  font-size:   22px;
  line-height: 33px;
  margin-block-end: 2rem;
}
