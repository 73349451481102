@use '../abstracts/colors/palette';

@mixin size-9() {
  font-size: 0.5625rem;
  line-height: 0.6875rem;
}

@mixin size-12() {
  font-size: 0.75rem;
  line-height: 0.9375rem;
}

@mixin size-14() {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

@mixin size-16() {
  font-size: 1rem;
  line-height: 1.25rem;
}

@mixin size-17() {
  font-size: 1.0625rem;
  line-height: 1.375rem;
}

@mixin size-18() {
  font-size: 1.125rem;
  line-height: 1.4375rem;
}

@mixin size-19() {
  font-size: 1.1875rem;
  line-height: 1.5rem;
}

@mixin size-20() {
  font-size: 1.25rem;
  line-height: 1.625rem;
}

@mixin size-22() {
  font-size: 1.375rem;
  line-height: 1.75rem;
}

@mixin size-24() {
  font-size: 1.5rem;
  line-height: 1.875rem;
}

@mixin size-27() {
  font-size: 1.6875rem;
  line-height: 2.25rem;
}

@mixin size-32() {
  font-size: 2rem;
  line-height: 2.5rem;
}

@mixin normal() {
  font-weight: 400;
  letter-spacing: 0;
}

@mixin semibold() {
  font-weight: 600;
  letter-spacing: 0;
}

@mixin bold() {
  font-weight: 700;
  letter-spacing: 0;
}

@mixin control-error() {
  @include size-16;
  color: palette.$ff-red-120;
  margin: 0;
}

@mixin private-text-size-styles() {
  .ff-text-9 {
    @include size-9;
  }

  .ff-text-12 {
    @include size-12;
  }

  .ff-text-14 {
    @include size-14;
  }

  .ff-text-16 {
    @include size-16;
  }

  .ff-text-17 {
    @include size-17;
  }

  .ff-text-18 {
    @include size-18;
  }

  .ff-text-19 {
    @include size-19;
  }

  .ff-text-20 {
    @include size-20;
  }

  .ff-text-22 {
    @include size-22;
  }

  .ff-text-24 {
    @include size-24;
  }

  .ff-text-27 {
    @include size-27;
  }

  .ff-text-32 {
    @include size-32;
  }

  .ff-text-normal {
    @include normal;
  }

  .ff-text-semibold {
    @include semibold;
  }

  .ff-text-bold {
    @include bold;
  }

  .ff-text-control-error {
    @include control-error();
  }
}
