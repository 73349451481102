@use '../abstracts/borders';
@use '../abstracts/colors/palette';
@use '../abstracts/colors/semantic/border' as borderColor;
@use '../abstracts/colors/semantic/text';
@use '../abstracts/mixins';
@use '../abstracts/variables';
@use '../base/typography';
@use './table';

@mixin card() {
  background-color: palette.$ff-sand-60;
  padding: 1rem;
  border-radius: borders.$ff-border-radius-default;
  border: 1px solid borderColor.$ff-border-clr-100;
  color: text.$ff-text-clr-100;

  @include mixins.container-query(small) {
    padding: 1.5rem;
  }
}

@mixin private-card-styles() {
  .ff-card {
    @include card;
  }
}

@mixin card-table() {
  @include table.table;
}

// TODO: Rename to .ff-table (and remove the table-prefix)
@mixin private-card-table-styles() {
  table.ff-card-table {
    @include card-table;
  }
}

@mixin card-list-item() {
  background-color: palette.$ff-sand-50;
  border: 1px solid borderColor.$ff-border-clr-100;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.04);
  padding: 12px 22px;
}

@mixin private-card-list-item-styles() {
  .ff-card-list-item {
    @include card-list-item;
  }
}
