@use 'sass:map';
@use 'sass:meta';
@use '../abstracts/breakpoints';

@mixin private-container-narrow-styles() {
  .ff-container-narrow {
    display: block;
    margin: 0 auto 4rem;
    max-width: breakpoints.$ff-br-content-medium;
    width: 100%;

    @media (min-width: 992px) {
      max-width: 800px;
    }

    @media (min-width: 1200px) {
      max-width: breakpoints.$ff-br-content-wide;
    }
  }
}

@mixin private-container-extra-narrow-styles() {
  .ff-container-extra-narrow {
    display: block;
    margin: 0 auto 4rem;
    max-width: breakpoints.$ff-br-content-medium;
  }
}

/**
 * Pages not using 100% width
 */
@mixin private-page-narrow-styles() {
  .ff-page-narrow {
    display: block;
    margin: 0 auto 4rem;
    max-width: breakpoints.$ff-br-content-medium;
  }
}

@mixin grid($size: wide) {
  $max-width: map.get(breakpoints.$ff-content-widths, wide);

  @if map.has-key(breakpoints.$ff-content-widths, $size) {
    $max-width: map.get(breakpoints.$ff-content-widths, $size);
  } @else if meta.type-of($size) == number {
    $max-width: $size;
  } @else {
    @error 'Argument for mixin grid: "#{$size}" needs to be either #{map.keys(breakpoints.$ff-content-widths)} or a number like "650px"';
  }

  $content-width: min(
    100% - breakpoints.$ff-content-inline-margin * 2,
    $max-width
  );

  display: grid;
  grid-template-columns: 1fr [content-start] $content-width [content-end] 1fr;

  & > * {
    grid-column: content;
  }
}
