// FF Red
$ff-red-150: #970705;
$ff-red-120: #d32b1f;
$ff-red-100: #fa3e34;
$ff-red-70: #fc7871;
$ff-red-50: #fc9e99;
$ff-red-20: #fed8d6;

// FF Logo Red
$ff-logo-red-100: #e74133;

// FF Universal design Red (better contrast ratio)
$ff-red-uu-100: #ea1106;

// FF Violet
$ff-violet-100: #bb29bb;

// FF Green
$ff-green-100: #16b971;
$ff-green-70: #5cce9c;

// FF Teal
$ff-teal-100: #26acc8;
$ff-teal-70: #66c5d9;
$ff-teal-50: #91d5e3;
$ff-teal-20: #d3eef4;

// FF Teal Shade
$ff-teal-shade-100: #007e95;
$ff-teal-shade-70: #4da5b5;
$ff-teal-shade-50: #80beca;
$ff-teal-shade-20: #cce5ea;

// FF Yellow
$ff-yellow-100: #ffce00;
$ff-yellow-70: #ffdd4d;
$ff-yellow-50: #ffe680;
$ff-yellow-20: #fff5cc;

// FF Sand
$ff-sand-100: #eeeae5;
$ff-sand-70: #f3f0ed;
$ff-sand-65: #f4f1ec;
$ff-sand-60: #f6f4f0;
$ff-sand-50: #faf8f4;

// FF Gray
$ff-gray-120: #a29f9c;
$ff-gray-100: #b5b2af;

// FF White
$ff-white-100: #ffffff;

