@use '../abstracts/colors/semantic/border';
@use '../abstracts/colors/semantic/text';

/*
Deprecated. No alternative is defined yet. Should define alternative instead of using this.
*/
@mixin private-typeahead-items-result-styles {
  .ff-typeahead-items-result {
    display: block;
    padding: 0.75em 0.6em 0.75em 1em;

    &:not([data-index='0']) {
      // Cannot use :first-child since each result is nested inside an app-list-item
      border-top: 1px solid border.$ff-border-clr-70;
    }

    &.active,
    &:focus,
    &:hover {
      background: rgba(0, 0, 0, 0.06);
    }

    > .result-wrapper {
      .code,
      .description {
        color: text.$ff-text-clr-100;
        margin-left: auto;
      }

      .name {
        font-weight: 600;
        color: text.$ff-text-clr-70;
        margin-bottom: 0.1em;
      }
    }
  }
}
