@use '../../utilities/outline';
@use '../../base/typography';

// @deprecated
@mixin outline() {
  @at-root
  form.ng-submitted  .ff-ngx-control-error.ng-invalid,
  &.ff-ngx-control-error.ng-touched.ng-invalid,
  &.ff-ngx-control-error.ng-touched.invalid {
    &:not(.transparent) {
      @include outline.error;

      &:focus-visible,
      &:focus,
      &:has(input:focus-visible) {
        @include outline.focused-red;
      }
    }
  }
}

// TODO: Rename
@mixin text() {
  @include typography.control-error;
  visibility: hidden;

  &.collapse,
  &.transparent {
    display: none;
  }

  @at-root form.ng-submitted .ng-invalid + &,
  .ng-touched.ng-invalid + &,
  .ng-touched.invalid + & {
    &:not(.transparent) {
      visibility: visible;

      &.collapse {
        display: block;
      }
    }
  }
}

@mixin form-control-error() {
  &:not(.transparent) {
    @include outline.error;

    &:focus-visible,
    &:focus,
    &:has(input:focus-visible) {
      @include outline.focused-red;
    }
  }
}

@mixin private-control-error-styles() {
  .ff-control-error {
    @include form-control-error();
  }
}

@mixin private-control-error-outline-styles() {
  .ff-control-error-outline {
    @include outline;
  }
}

@mixin private-control-error-text-styles() {
  .ff-control-error-text {
    @include text;
  }
}
