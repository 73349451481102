// COLORS
// Reds https://coolors.co/970705-d40b00-ea1106
$clr-dark-red: #970705;
$clr-rosso-corsa: #d40b00;
$clr-vermillion: #ea1106;
$clr-red-50: #fc9e99;
$clr-red-120: #d32b1f;
$clr-cosmos: #fed8d6;
// Grays https://coolors.co/2f2f2f-3c3c3b-555554-6d6d6d-b5b2af
$clr-jet: #2f2f2f;
$clr-onyx: #3c3c3b;
$clr-granite-gray: #666666;
$clr-dim-gray: #6d6d6d;
$clr-gray: #828282;
$clr-tide: #b5b2af;
// Whites https://coolors.co/d8d9d9-dcdad3-e7e5df-f4f1eC-eeeae5-f6f4f0-f6f7f9-faf8f4
$clr-gainsboro: #d8d9d9;
$clr-timberwolf: #dcdad3;
$clr-platinum: #eeeae5;
$clr-isabelline: #e7e5df;
$clr-neutral-gray: #f3f0ed;
$clr-pampas: #f6f4f0;
$clr-cultured: #f6f7f9;
$clr-linen: #faf8f4;
$clr-white: #fff;
$clr-seashell: #f1f1f1;
$clr-sand-50: #faf8f4;

// Project palette
$clr-border-60: $clr-tide;
$clr-border-80: $clr-timberwolf;
$clr-border: $clr-isabelline;
$clr-border-120: $clr-cultured;
$clr-disabled: $clr-granite-gray;
$clr-red: $clr-vermillion;
$clr-red-hover: $clr-rosso-corsa;
$clr-red-light: $clr-cosmos;
$clr-red-dark: $clr-dark-red;
$clr-green: #16b971;
$clr-green-70: #5cce9c;
$clr-yellow: #ffce00;
$clr-gray: $clr-granite-gray;
$clr-gray-120: $clr-neutral-gray;
$clr-sand-80: $clr-gainsboro;
$clr-sand-90: $clr-timberwolf;
$clr-sand: $clr-platinum;
$clr-sand-120: $clr-isabelline;
$clr-sand-125: #f4f1ec;
$clr-sand-130: $clr-pampas;
$clr-sand-150: $clr-linen;
$clr-text-70: $clr-jet;
$clr-text-60: $clr-gray;
$clr-text: $clr-onyx;
$clr-text-120: $clr-dim-gray;
$clr-ff-red: $clr-red-120;

// Context colors
$primary-btn-background-color: $clr-red;
$primary-btn-color: $clr-white;
$secondary-btn-background-color: $clr-tide;
$secondary-btn-color: $clr-white;
$error-background-color: $clr-red-light;

// Breakpoints
$br-content-small: 611px;
$br-content-medium: 768px;
$br-content-wide: 1080px;
$br-buffer: 50px;

// Sizing and grids
$buttons-grid-gap: 1rem;
$buttons-min-col-width: 12rem;
$vertical-gutter: 2rem;
$default-border-radius: 4px;

// Radius
$border-radius-box: 5px;
$border-radius-default: 4px;
