@mixin form() {
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  gap: 0.5em;
}

@mixin private-form-styles() {
  .ff-form {
    @include form;
  }
}
