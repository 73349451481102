@use '../palette';
@use './border';
@use './text';

$ff-btn-clr-disabled-bg: palette.$ff-gray-100;
$ff-btn-clr-disabled-text: palette.$ff-white-100;

$ff-btn-clr-primary-bg: palette.$ff-red-100;
$ff-btn-clr-primary-text: palette.$ff-white-100;
$ff-btn-clr-primary-bg-hover: palette.$ff-red-120;
$ff-btn-clr-primary-text-hover: palette.$ff-white-100;
$ff-btn-clr-primary-inner-shadow: palette.$ff-logo-red-100;

$ff-btn-clr-secondary-bg: palette.$ff-sand-65;
$ff-btn-clr-secondary-text: text.$ff-text-clr-100;
$ff-btn-clr-secondary-bg-hover: darken(palette.$ff-sand-65, 5%);
$ff-btn-clr-secondary-text-hover: text.$ff-text-clr-100;
$ff-btn-clr-secondary-inner-shadow: border.$ff-border-clr-120;
