$ff-br-content-small: 592px;
$ff-br-content-medium: 768px;
$ff-br-content-wide: 956px;

$ff-content-inline-margin: 1rem;

$ff-content-width-wide: calc(
  $ff-br-content-wide - $ff-content-inline-margin * 2
);
$ff-content-width-medium: calc(
  $ff-br-content-medium - $ff-content-inline-margin * 2
);
$ff-content-width-small: calc(
  $ff-br-content-small - $ff-content-inline-margin * 2
);

$ff-content-widths: (
  small: $ff-content-width-small,
  medium: $ff-content-width-medium,
  wide: $ff-content-width-wide,
);
